<template>
    <div>
		<fetch-loader v-if="pending || error" :pending="pending" :error="error" @refresh="refresh" flat/>
		<template v-else>
			<template v-for="connection in connections" :key="connection.partner.value">
				<document-item v-if="!connection.partner.alpha || $auth.user?.is_admin"
							   :ref="el => {connectionComponents[connection.partner.value] = el}"
							   :title="connection.partner.title"
							   :icon-width="32"
							   :data="{redirect_url: buildRedirectUrl(connection)}"
							   :method="connection.enabled ? 'DELETE' : 'POST'"
							   :notification="connection.enabled ? 'destroyed' : undefined"
							   :selected="connection.enabled"
							   :loading="connectionLoaders[connection.partner.value]"
							   :confirm="connection.enabled ? {title: $t('confirm.connection.destroy.title'), message: $t('confirm.connection.destroy.message')} : undefined"
							   :description="connection.partner.description"
							   :action="connection.enabled ? `/v1/athletes/${props.userKey}/connections/${connection.partner.value}` : `/v1/athletes/${props.userKey}/connections/${connection.partner.value}/auth`"
							   @success="data => handleConnectorRequest(connection, data)">
					<template #icon>
						<cdn-image :size="{width: 32}" :ratio="connection.partner.image.ratio" :src="connection.partner.image"/>
					</template>
					<template v-if="!connection.enabled && connection.partner.connect_image" #description>
						<cdn-image :size="{height: 32}" :ratio="connection.partner.connect_image.ratio" :src="connection.partner.connect_image" class="mt-8"/>
					</template>
					<template v-if="!connection.enabled" #suffix>
						<badge v-if="connection.partner.alpha" rounded spaced color="secondary">ALPHA</badge>
						<badge v-else-if="connection.partner.beta" rounded spaced color="secondary">BETA</badge>
						<icon v-else :icon="icons.next" large color="gray-400"/>
					</template>
					<template v-if="connection.enabled" #actions>
						<nav-list-item v-if="connection.partner.allows_backfill" @click="openSubmitBackfillModal(connection)">
							{{ $t('action.transfer_past_activities') }}
						</nav-list-item>
						<nav-list-item @click="disconnect(connection)" color="error">
							{{ $t('action.destroy') }}
						</nav-list-item>
					</template>
				</document-item>
			</template>

			<ajax-form-modal v-model="showSubmitBackfillModal"
								  :submit-text="$t('action.transfer')"
								  :form-options="{action: selectedConnection ? `/v1/athletes/${props.userKey}/connections/${selectedConnection.partner.value}/backfill` : null, data: backfillData, method: 'POST'}"
								  :modal-options="{subtitle: $t('text.transfer_past_activities_of_partner', {partner: selectedConnection?.partner.title ?? ''}), title: `${$t('action.transfer_past_activities')}: ${selectedConnection?.partner.title ?? ''}`}">

				<row>
					<column sm="6">
						<form-date-input v-model="backfillData.from"
										 :label="$t('label.min_date')"
										 :rules="{required: true, min: $date().subtract(90, 'days').format(), max: backfillData.to ?? $date().format()}"/>
					</column>
					<column sm="6">
						<form-date-input v-model="backfillData.to"
										 :label="$t('label.max_date')"
										 :rules="{required: true, min: backfillData.from ?? $date().subtract(90, 'days').format(), max: $date().format()}"/>
					</column>
				</row>

			</ajax-form-modal>
		</template>
	</div>
</template>

<script setup lang="ts">
import {nextIcon} from "@spoferan/nuxt-spoferan/icons";

const {$apiFetch, $notify} = useNuxtApp();
const route = useRoute();
const router = useRouter();
const {t} = useI18n();

const props = withDefaults(defineProps<{
	userKey?: string|number
	types?: string[] // The explicit types to fetch exclusively
}>(), {
	userKey: 'my'
});
const emit = defineEmits(['updated']);

const icons = {
	next: nextIcon,
};

const connectionComponents = ref([]);
const connectionLoaders = ref({});

const showSubmitBackfillModal = ref(false);
const backfillData = ref({});
const selectedConnection = ref(undefined);

const {reactiveData: connections, pending, error, refresh} = await useApiFetch(`/v1/athletes/${props.userKey}/connections`, {
	auth: true,
	params: {
		types: props.types
	}
});
watch(connections, (newValue) => {
	if (newValue && route.query.connect_type) {
		const queryConnection = newValue.find(connection => connection.partner.value === route.query.connect_type);
		if (queryConnection && !queryConnection.enabled) {
			let formData: {[key: string]: any} = {};

			let hasValidConnectParams = false;
			switch (queryConnection.partner.value) {
				case 'garmin':
					formData = {
						oauth_token: route.query.oauth_token,
						oauth_verifier: route.query.oauth_verifier && route.query.oauth_verifier !== 'null' ? route.query.oauth_verifier : undefined, // Null when oauth process was cancelled
						oauth_token_secret: route.query.oauth_token_secret,
					}
					hasValidConnectParams = formData.oauth_token && formData.oauth_verifier && formData.oauth_token_secret;

					break;
				case 'suunto':
					formData = {
						code: route.query.code,
						redirect_url: buildRedirectUrl(queryConnection)
					}
					hasValidConnectParams = formData.code;
					break;
				case 'strava':
					formData = {
						code: route.query.code
					}
					hasValidConnectParams = formData.code;
					break;
			}
			if (!hasValidConnectParams) {
				return;
			}

			connect(queryConnection, formData)
		}
	}
}, {immediate: true});

function connect(connection, formData) {
	connectionLoaders.value[connection.partner.value] = true;

	$apiFetch(`/v1/athletes/${props.userKey}/connections/${connection.partner.value}/connect`, {
		method: 'POST',
		params: formData
	}).then(data => {
		$notify('success', t('notification.added.message'));
		handleSuccessfulConnection(data.data);
	}).finally(() => {
		// We want to keep the loader active until the redirect is done for improved UX
		if (route.query.redirect && route.query.redirect !== route.path) {
			router.replace(route.query.redirect);
		} else {
			connectionLoaders.value[connection.partner.value] = false;
		}
	});
}

function handleConnectorRequest(connection, data) {
	if (connection.enabled) {
		handleConnectionDisconnect(data);
	} else {
		handleConnectAuthRequest(data);
	}
}

function handleConnectAuthRequest(data) {
	window.location.href = data.url;
}

function handleSuccessfulConnection(data) {
	updateConnectionData(data);
	emit('updated');
}

function handleConnectionDisconnect(data) {
	updateConnectionData(data);
	emit('updated');
}

function updateConnectionData(data) {
	const connectionIndex = connections.value.findIndex(connection => connection.partner.value === data.partner.value);
	connections.value[connectionIndex] = data;
}

function disconnect(connection) {
	connectionComponents.value[connection.partner.value].submit();
}

function openSubmitBackfillModal(connection) {
	selectedConnection.value = connection;
	showSubmitBackfillModal.value = true;
}

function buildRedirectUrl(connection) {
	// We always use the static connection management page as redirect target as some connection partners require a static redirect url, e.g. Suunto.
	// But we still want to redirect the user back to the page he came from whereby we need to persist the original redirect url as redirect param.
	const baseRedirectPath = '/my/profile/manage/connections';
	const queryParams: {[key: string]: string} = {
		connect_type: connection.partner.value
	};
	if (route.query.redirect) {
		queryParams.redirect = route.query.redirect;
	} else if (route.path !== baseRedirectPath) {
		queryParams.redirect = route.path;
	}

	return router.resolve({path: baseRedirectPath, query: queryParams}).href
}
</script>

